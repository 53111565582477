const HELP = '';
const KAKAO = 'https://open.kakao.com/me/Ottle';

/**
 * Customer Service URLs
 */
export const CS_URL = {
    KAKAO,
    HELP,
};
